import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import ModalWindow from "../templates/ModalWindow";
import PostAddIcon from "@mui/icons-material/PostAdd";

const CreateReport = (props) => {
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [template, setTemplate] = React.useState("");

  const initialContent = `
  <h1>Welcome!</h1>
  <h2>This is a sample editor</h2>
  <p>Edit this text to see how it works.</p>
`;

  const prefillJson = {
    formId: "template1",
    description: "desc",
    name: "name",
    email: "email",
  };

  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState(false);

  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
  };

  const handleOpenTemplateDialog = () => {
    setOpenTemplateDialog(true);
  };

  const handleCloseTemplateDialog = () => {
    setOpenTemplateDialog(false);
    props.func();
    props.func1();
  };

  const handleOpenFullScreenDialog = () => {
    if (selectedTemplate) {
      setOpenFullScreenDialog(true);
      setOpenTemplateDialog(false);
    } else {
      alert("Please select a template!");
    }
  };

  const names = ["Radiology Report"];
  const smallCaseArray = Array.isArray(props.existing)
    ? props.existing.map((item) => item.toLowerCase())
    : [];

  return (
    <>
      <Button
        sx={{
          background: "#80848c",
          fontSize: "11px",
          ":hover": {
            bgcolor: "#0290A2",
            color: "black",
          },
        }}
        onClick={handleOpenTemplateDialog}
        variant="contained"
        endIcon={<PostAddIcon />}
      >
        Create report
      </Button>
      <Dialog open={openTemplateDialog} onClose={handleCloseTemplateDialog}>
        <DialogTitle>Select Template</DialogTitle>
        <Divider />
        <DialogContent>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "45ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="select-template">Select Template</InputLabel>
              <Select
                labelId="select-template"
                id="select-template"
                value={selectedTemplate}
                onChange={handleTemplateChange}
                input={<OutlinedInput label="Select Template" />}
              >
                {names.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    disabled={smallCaseArray.includes(
                      name.toLowerCase().replace(/\s+/g, "")
                    )}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Divider />
          </Box>
          <DialogActions>
            <Button onClick={handleOpenFullScreenDialog}>Create</Button>
            <Button onClick={handleCloseTemplateDialog}>Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {openFullScreenDialog && (
        <ModalWindow
          open={openFullScreenDialog}
          setOpen={setOpenFullScreenDialog}
          templateName={selectedTemplate}
          data={props}
          func1={props.func1}
          func2={handleCloseTemplateDialog}
        />
      )}
    </>
  );
};

export default CreateReport;
