import React, { useRef, useState, useEffect, forwardRef, version } from "react";
import {
  CssBaseline,
  GlobalStyles,
  Box,
  useTheme,
  Stack,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";

import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuButtonUnderline,
  MenuControlsContainer,
  MenuSelectFontFamily,
  RichTextEditor,
  MenuSelectHeading,
  MenuButtonSubscript,
  MenuButtonSuperscript,
  MenuButtonTextColor,
  MenuDivider,
  MenuSelectFontSize,
  MenuSelectTextAlign,
  MenuButtonUndo,
  MenuButtonRedo,
} from "mui-tiptap";
import useExtensions from "./useExtensions";
import RadiologyExaminationContent from "./RadiologyExaminationContent";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const RadiologyExamination = (props) => {
  const theme = useTheme();
  const rteRef = useRef();
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setOpen(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_PHP_URL}/saveTemplate.php`,
        {
          sid: props.token,
          jsonContent: generateJSONContent(),
          studyId: props.id,
          type: "radiologyExamination",
        }
      );
      console.log(res);
      if (res.data === "success") {
        setOpen(false);
        setErrorText("Successfully added the modality");
      } else if (res.data === "exist") {
        setErrorText("Modality with the name already exist");
      } else {
        setErrorText("Something went wrong");
      }

      setOpenError(true);
    } catch (error) {
      if (error.response) {
        setErrorText(error.response.data.msg);
      }
    }
    // submit modal
    props.func2();
  };

  const fontOptions = [
    { label: "Arial", value: "Arial" },
    { label: "Georgia", value: "Georgia" },
    { label: "Courier New", value: "Courier New" },
    { label: "Times New Roman", value: "Times New Roman" },
    { label: "Verdana", value: "Verdana" },
  ];

  const handleFontFamilyChange = (fontFamily) => {
    rteRef.current?.editor.commands.setFontFamily(fontFamily);
  };
  const extensions = useExtensions({
    placeholder: "Add your own content here...",
  });

  const formatDate = (dateString) => {
    if (!dateString) return "";
    return dateString.length === 8
      ? dateString.slice(6, 8) +
          "/" +
          dateString.slice(4, 6) +
          "/" +
          dateString.slice(0, 4)
      : "";
  };

  const reportData = {
    patientName: props.details?.PatientMainDicomTags?.PatientName ?? "",
    ownerName: props.details?.PatientMainDicomTags?.ResponsiblePerson ?? "",

    patientID: props.details?.PatientMainDicomTags?.PatientID ?? "",
    gender: props.details?.PatientMainDicomTags?.PatientSex ?? "",
    birthDate: formatDate(
      props.details?.PatientMainDicomTags?.PatientBirthDate ?? ""
    ),
    img: props.img?.join("") ?? "",
    studyDate: formatDate(props.details?.MainDicomTags?.StudyDate ?? ""),
  };

  const content = props.content
    ? props.content
    : RadiologyExaminationContent(reportData);
  const generateJSONContent = () => {
    const htmlContent = rteRef.current?.editor?.getJSON() ?? "";
    return htmlContent;
  };
  if (open) {
    return (
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else
    return (
      <>
        <CssBaseline />
        <GlobalStyles
          styles={{
            ".ProseMirror": {
              backgroundColor: "#ffffff",
              color: "#000000",
              padding: "16px",
              borderRadius: "4px",

              margin: "auto",
              overflowY: "auto",
            },
          }}
        />

        <RichTextEditor
          ref={rteRef}
          extensions={extensions}
          content={content}
          renderControls={() => (
            <MenuControlsContainer
              sx={{
                backgroundColor: "#f0f0f0",
                color: "#333333",
                padding: "8px",
                borderRadius: "4px",
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              {/* <MenuSelectFontFamily
              options={fontOptions}
              defaultValue="Arial"
              onChange={handleFontFamilyChange}
              sx={{
                backgroundColor: "white",
                color: "black",
              }}
            /> */}
              <MenuSelectHeading
                sx={{ backgroundColor: "white", color: "black" }}
              />
              <MenuDivider sx={{ backgroundColor: "white" }} />

              <MenuSelectFontSize
                sx={{
                  backgroundColor: "white",
                  color: "black",
                }}
              />

              <MenuDivider sx={{ backgroundColor: "white" }} />

              <MenuButtonBold sx={{ color: "white" }} />

              <MenuButtonItalic sx={{ color: "white" }} />

              <MenuButtonUnderline sx={{ color: "white" }} />

              <MenuButtonSubscript sx={{ color: "white" }} />

              <MenuButtonSuperscript sx={{ color: "white" }} />

              <MenuDivider sx={{ backgroundColor: "white" }} />

              <MenuButtonTextColor
                defaultTextColor={theme.palette.text.primary}
                swatchColors={[
                  { value: "#000000", label: "Black" },
                  { value: "#ffffff", label: "White" },
                  { value: "#888888", label: "Grey" },
                  { value: "#ff0000", label: "Red" },
                  { value: "#ff9900", label: "Orange" },
                  { value: "#ffff00", label: "Yellow" },
                  { value: "#00d000", label: "Green" },
                  { value: "#0000ff", label: "Blue" },
                ]}
                sx={{ color: "white" }}
              />
              <MenuDivider sx={{ backgroundColor: "white" }} />
              <MenuSelectTextAlign
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiSvgIcon-root": {
                        color: "#D3D3D3",
                      },
                    },
                  },
                }}
                sx={{
                  backgroundColor: "#D3D3D3",
                }}
              />

              <MenuDivider sx={{ backgroundColor: "white" }} />
              <MenuButtonUndo
                sx={{ color: "white", "&.Mui-disabled": { color: "gray" } }}
              />
              <MenuButtonRedo
                sx={{ color: "white", "&.Mui-disabled": { color: "gray" } }}
              />
              <Tooltip title="Save" placement="right" arrow>
                <IconButton sx={{ fontSize: 24 }} onClick={handleSubmit}>
                  <SaveIcon sx={{ fontSize: 24, color: "white" }} />
                </IconButton>
              </Tooltip>
            </MenuControlsContainer>
          )}
        />
      </>
    );
};

export default RadiologyExamination;
