import React, { useState } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Slide,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RadiologyReport from "./RadiologyReport";
import RadiologyExamination from "./RadiologyExamination";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalWindow({
  open,
  setOpen,
  templateName,
  data,
  draft,
  content,
  type,
  func1,
  func2,
  func3,
}) {
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [uploading, setUploading] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [openUplaod, setOpenUpload] = useState(false);

  const handleExport = async () => {
    if (typeof func3 === "function") {
      func3();
    }
    const element = document.querySelector(".ProseMirror");
    if (!element) {
      console.error("Element not found");
      return;
    }
    const canvas = await html2canvas(element, {
      scale: 4,
      useCORS: true,
      allowTaint: true,
    });
    const imgWidth = 210;
    const pageHeight = 297;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const pdf = new jsPDF("p", "mm", "a4");
    let position = 0;
    let pageCanvas = document.createElement("canvas");
    let ctx = pageCanvas.getContext("2d");
    pageCanvas.width = canvas.width;
    pageCanvas.height = (pageHeight * canvas.width) / imgWidth;
    while (position < canvas.height) {
      ctx.clearRect(0, 0, pageCanvas.width, pageCanvas.height);
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, pageCanvas.width, pageCanvas.height);
      ctx.drawImage(
        canvas,
        0,
        position,
        pageCanvas.width,
        pageCanvas.height,
        0,
        0,
        pageCanvas.width,
        pageCanvas.height
      );
      const imgData = pageCanvas.toDataURL("image/jpeg", 0.98);
      pdf.addImage(
        imgData,
        "JPEG",
        10,
        10,
        imgWidth - 20,
        (pageCanvas.height * (imgWidth - 20)) / pageCanvas.width
      );

      position += pageCanvas.height;
      if (position < canvas.height) {
        pdf.addPage();
      }
    }
    pdf.save("Report.pdf");
    await func1();
  };

  const handleUpload = async () => {
    if (typeof func3 === "function") {
      func2();
    }
    if (typeof func3 === "function") {
      func3();
    }
    setUploading(true);
    setOpenUpload(false);
    const element = document.querySelector(".ProseMirror");

    if (!element) {
      console.error("Element not found");
      return;
    }

    const canvas = await html2canvas(element, {
      scale: 4,
      useCORS: true,
      allowTaint: true,
    });
    const imgWidth = 210;
    const pageHeight = 297;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const pdf = new jsPDF("p", "mm", "a4");

    let position = 0;
    let pageCanvas = document.createElement("canvas");
    let ctx = pageCanvas.getContext("2d");

    pageCanvas.width = canvas.width;
    pageCanvas.height = (pageHeight * canvas.width) / imgWidth;

    while (position < canvas.height) {
      ctx.clearRect(0, 0, pageCanvas.width, pageCanvas.height);
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, pageCanvas.width, pageCanvas.height);
      ctx.drawImage(
        canvas,
        0,
        position,
        pageCanvas.width,
        pageCanvas.height,
        0,
        0,
        pageCanvas.width,
        pageCanvas.height
      );

      const imgData = pageCanvas.toDataURL("image/jpeg", 0.98);
      pdf.addImage(
        imgData,
        "JPEG",
        10,
        10,
        imgWidth - 20,
        (pageCanvas.height * (imgWidth - 20)) / pageCanvas.width
      );

      position += pageCanvas.height;

      if (position < canvas.height) {
        pdf.addPage();
      }
    }

    const blob = pdf.output("blob");
    const file = new File([blob], "Report.pdf", { type: "application/pdf" });
    const formData = new FormData();
    formData.append("file", file);
    formData.append("sid", data.token);
    formData.append("seriesid", data.id || data.row.ID);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_PHP_URL}/uploadReport.php`,
        formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.Status === "Success") {
        setErrorText("File uploaded successfully!");
        setAlertSeverity("success");
        setOpen(false);
        await func1();
        setUploading(false);
        setOpenUpload(false);
      } else {
        setErrorText("Failed to upload file. Please try again.");
        setAlertSeverity("error");
      }
    } catch (error) {
      setErrorText("An error occurred during file upload.");
      setAlertSeverity("error");
    }
    setOpenError(true);
    setOpenUpload(false);
  };

  const handleClose = async () => {
    if (typeof func3 === "function") {
      func3();
    }
    setOpen(false);
    await func1();
  };
  const renderTemplate = () => {
    const commonProps = {
      content,
      details: data.details,
      token: data.token,
      id: data.id || data.row.ID,
      img: data.img,
      style: { width: "16in", maxWidth: "100%" },
      func2: handleClose,
    };

    switch (templateName.toLowerCase()) {
      case "radiology report":
      case "radiologyreport":
        return <RadiologyReport {...commonProps} />;
      case "radiology examination":
      case "radiologyexamination":
        return <RadiologyExamination {...commonProps} />;
      default:
        return null;
    }
  };
  if (uploading) {
    return (
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={uploading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else
    return (
      <>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
                Selected Template: Radiology Report
              </Typography>
              <Button
                autoFocus
                color="inherit"
                onClick={handleExport}
                sx={{
                  background: "#80848c",
                  fontSize: "11px",
                  ":hover": {
                    bgcolor: "#0290A2",
                    color: "black",
                  },
                  textTransform: "none",
                  marginRight: "8px",
                }}
              >
                Download PDF
              </Button>
              <Button
                autoFocus
                color="inherit"
                onClick={() => setOpenUpload(true)}
                sx={{
                  background: "#80848c",
                  fontSize: "11px",
                  ":hover": {
                    bgcolor: "#0290A2",
                    color: "black",
                  },
                  textTransform: "none",
                }}
              >
                Attach PDF to DICOM
              </Button>
            </Toolbar>
          </AppBar>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              backgroundColor: "transparent",
            }}
          >
            {renderTemplate()}
          </div>
        </Dialog>
        <Dialog
          open={openUplaod}
          onClose={() => setOpenUpload(false)}
          fullWidth
        >
          <DialogTitle>Attach PDF to DICOM</DialogTitle>
          <DialogContent>
            <Typography>Are you sure to upload this file</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleUpload()}>Yes</Button>
            <Button onClick={() => setOpenUpload(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </>
    );
}
