const RadiologyExaminationContent = ({
  patientName,
  ownerName,
  clinicName,
  patientID,
  gender,
  birthDate,
  species,
  breed,
  weight,
  img,
  studyDate,
}) => {
  return `  <table>
      <tbody>
        <tr>
          <th><strong>Referred By</strong></th>
          <th><strong>Patient Details</strong></th>
        </tr>
        <tr>
          <td>Name:</td>
          <td>Owner Name: <b>${ownerName}</td>
        </tr>
        <tr>
          <td>Clinic:</td>
          <td>Patient Name: <b>${patientName}</b></td>
        </tr>
        <tr>
          <td>Address:</td>
          <td>Patient ID: <b>${patientID}</b></td>
        </tr>
        <tr>
          <td>Phone:</td>
          <td>Gender: <b>${gender}</b></td>
        </tr>
        <tr>
          <td>Email:</td>
          <td>Birthdate & Age: <b>${birthDate}</b></td>
        </tr>
        <tr>
          <td>Study Date: <b>${studyDate}</b></td>
          <td>Species & Breed:</td>
        </tr>
        <tr>
          <td>Report Date:<b></b></td>
          <td>Weight:</td>
        </tr>
      </tbody>
    </table>
  <h4><u>Radiology Examination Findings:</u></h4><p></p>
    <h4><u>General Findings and Remarks:</u></h4><p></p>
  <h4><u>Comments</u></h4><p></p><p></p>
  <h4><u>Preview</u></h4>
  ${img}</p><h4><u>Notes</u></h4><p></p>
  
  `;
};

export default RadiologyExaminationContent;
